.HeadDiv{
    position: sticky;
    top: 0;
    background-color: white;
}

hr{
    color: #ff7b00;
}

.SubmitButton{
    width: 120px !important;
}

.EndFooter{
    position: absolute;
    bottom: 0px;
}

.EndTest__Text{
    margin: 2em;
}