.subject {
    margin-top: 5px;
    margin-left: 25px;
    margin-right: 25px;
    background-color: #ff7b00;
    height : 55px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: sticky;
    
}

.subs {
    display: flex;
    flex-direction: row;
}
.btndiv {
    margin-right : -40px;
   
}
.btn {
    color : white;
    cursor: pointer;
    font-size: 14px;
    background-color: #007ab6;
    width : 100px;
    height : 30px;
    margin-right: 35px;
    margin-left: 35px;
    border : none;
    border-radius: 10px;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.562);
}

.EnterenceHeader{
    color: white;
    padding: 1em;
}

