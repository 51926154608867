.headerp2div{
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headerh1{
    margin: 0px;
    font-size: x-large;
    vertical-align: middle;
}
.headerp1diva
{
    background-color: #f2f2f2;
    height: 13vh;
}