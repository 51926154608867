.headerp1div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18vh;
    overflow: hidden;
    position: sticky;
}
.headerp2div{
    width: 94%;
    display: flex;
    justify-content: space-between;
}
.headerc1div{
    width: 500px;
    font-size: 0px;
}
.headerc2div{
    text-align: left;
    width: 260px;
}
.headerh{
    margin: 0px;
}
.timerpdiv{
    display: flex;
    font-weight: 700;
    flex-direction: column;
}
.timercdiv{
    width: 55%;
}
.timerbg{
    background-color: #007ab6;
    border-radius: 10px;
    height : 25px;
    color: white;
    text-align: center;
    /* margin: 2px 40px; */
}