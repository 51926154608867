
.endfooter{
    width: 100%;
    height: 16vh;
    background-color: #007ab6;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;  
    position: absolute;
    bottom: 0px;
}
.fr{
    text-align: center; 
    padding-top: 20px;   
}
.img
{
    display:inline-block;
    height: 50px; 

}
