.Questions{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: start;
    padding-left: 5vw;
    padding-bottom: 3vw;
}
.ques{
    padding-left: 3vw;
}
.option
{
    padding-left: 2vw;
    padding-bottom: 7px;
}
#top
{
    padding-top: 15px ;
    padding-bottom: 15px;
}
#middle
{
    padding-bottom: 5px;
}
.opt{
    
    display: inline-block;
    margin-left: 1vw;
}